import { FC } from 'react';

import { sectionStyle } from 'holded/lib/styles';
import { cleanDoubleSlash } from 'holded/lib/utils';
import { ImageMedia } from 'holded/modules/cms/domain/imageMedia';
import { FooterSection, Page } from 'holded/modules/cms/domain/page';
import NewsletterFooter from 'holded/modules/cms/ui/sections/newsletter/NewsletterFooter';
import HeroIcon from 'holded/modules/cms/ui/shared/components/HeroIcon';
import NextImage from 'holded/modules/cms/ui/shared/components/Image';
import LocaleSwitch from 'holded/modules/cms/ui/shared/components/LocaleSwitch';

type Props = {
  footer: FooterSection;
  pageData: Page;
};

const DEFAULT_LANGUAGE = 'en';

const vismaLogoData: ImageMedia = {
  attributes: {
    mime: 'image/png',
    url: 'https://storage.googleapis.com/landing-strapi-v4/assets/png/visma-white-logo-rsFZgPKaCHGaHxSGAnKW7F.png',
  },
};

const VismaSection: FC<{ locale: 'es' | 'en' }> = ({ locale }) => {
  return (
    <div className="border-t border-gray-700 py-8 md:pb-0 flex flex-col md:flex-row items-center justify-between gap-8">
      <div>
        <NextImage media={vismaLogoData} classImg="!h-[21px] w-auto" />
      </div>
      <div className="flex gap-2 md:gap-3 items-center">
        <a
          href="https://www.visma.com/"
          className="text-body3 md:text-base text-gray-400 hover:opacity-80 border-r border-r-gray-400 pr-2 md:pr-3"
        >
          Visma Group
        </a>
        <a
          href="https://www.visma.es/"
          className="text-body3 md:text-base text-gray-400 hover:opacity-80 border-r border-r-gray-400 pr-2 md:pr-3"
        >
          {locale === 'es' ? 'Visma en España' : 'Visma in Spain'}
        </a>
        <a href="https://www.visma.com/careers" className="text-body3 md:text-base text-gray-400 hover:opacity-80">
          Visma Careers
        </a>
      </div>
    </div>
  );
};

const Footer = ({ footer, pageData }: Props) => {
  return (
    <>
      {footer && (
        <footer className={`bg-gray-hard hidden md:block`} aria-labelledby="footer-heading-desktop">
          <p id="footer-heading-desktop" className="sr-only">
            Footer
          </p>
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <div className="md:flex pb-10 md:pb-15">
              <div className="w-full md:h-footerTablet lg:h-footer flex md:flex-col flex-wrap -mx-1 sm:-mx-1 md:-mx-2 xl:-mx-2 justify-between">
                {footer?.attributes?.columns?.map((column) => (
                  <div
                    key={column.id}
                    className={'mt-1 w-1/2 sm:w-1/2 md:w-1/4 lg:w-1/4 mb-10 md:mb-0 xl:px-2 xl:w-1/4 pr-1.5'}
                  >
                    <p className={`text-sm font-semibold ${sectionStyle.textColor.secondary} tracking-wider uppercase`}>
                      {column.title}
                    </p>
                    <ul role="list" className="mt-4 space-y-4">
                      {column.items.map((item) => (
                        <li key={item.name}>
                          <a
                            href={item.href.startsWith('http') ? item.href : `/${cleanDoubleSlash(item.href)}`}
                            className={`text-base text-gray-300 hover:text-white`}
                            {...(item.newTab && { target: '_blank', rel: 'noreferrer' })}
                          >
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
              <div className="w-1/2 md:w-1/6">
                <p
                  className={`text-sm font-semibold mb-2 ${sectionStyle.textColor.secondary} tracking-wider uppercase`}
                >
                  {footer.attributes?.languageLabel}
                </p>
                <LocaleSwitch theme={'dark'} pageData={pageData} />
              </div>
            </div>
            {footer?.attributes?.newsletter && (
              <div className="border-t border-gray-700 py-8 lg:flex xl:mt-0 items-center">
                <NewsletterFooter {...footer.attributes.newsletter} />
              </div>
            )}
            <div className="py-8 md:flex md:items-center md:justify-between">
              <div className="flex items-center justify-center space-x-6 md:order-2">
                {footer?.attributes?.socialMedias?.data.map((item) => (
                  <a
                    key={item.attributes.name}
                    href={item.attributes?.href ?? ''}
                    className={`${sectionStyle.textColor.secondary} hover:text-gray-300`}
                  >
                    <span className="sr-only">{item.attributes.name}</span>
                    {item.attributes.image ? (
                      <NextImage media={item.attributes.image.data} classImg={'object-cover'} width={20} height={20} />
                    ) : (
                      <HeroIcon icon={item.attributes.icon} outline={true} />
                    )}
                  </a>
                ))}
              </div>
              <p className={`mt-8 text-base ${sectionStyle.textColor.secondary} md:mt-0 md:order-1`}>
                &copy; {footer?.attributes?.copyright}
              </p>
            </div>
            <VismaSection locale={pageData.attributes?.locale || DEFAULT_LANGUAGE} />
          </div>
        </footer>
      )}
      {footer && (
        <footer className={`bg-gray-hard md:hidden block`} aria-labelledby="footer-heading-mobile">
          <p id="footer-heading-mobile" className="sr-only">
            Footer
          </p>
          <div className="py-5 px-4">
            <div className="pb-10">
              <div className="w-full flex flex-col flex-wrap h-footerMobile gap-y-5">
                {footer?.attributes?.columns?.map((column) => (
                  <div key={column.id} className={'w-1/2'}>
                    <ul role="list" className="mt-4 space-y-4 px-1">
                      <span
                        className={`text-sm font-semibold ${sectionStyle.textColor.secondary} tracking-wider uppercase`}
                      >
                        {column.title}
                      </span>
                      {column.items.map((item) => (
                        <li key={item.name}>
                          <a
                            href={item.href.startsWith('http') ? item.href : `/${cleanDoubleSlash(item.href)}`}
                            className={`text-base text-gray-300 hover:text-white`}
                          >
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
              <div className="w-full md:w-1/6 mt-10">
                <p
                  className={`text-sm font-semibold mb-2 ${sectionStyle.textColor.secondary} tracking-wider uppercase`}
                >
                  {footer.attributes?.languageLabel}
                </p>
                <LocaleSwitch theme={'dark'} pageData={pageData} />
              </div>
            </div>
            {footer?.attributes?.newsletter && (
              <div className="border-t border-gray-700 py-8">
                <NewsletterFooter {...footer.attributes.newsletter} />
              </div>
            )}
            <div className="my-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
              <div className="flex items-center justify-center space-x-6 md:order-2">
                {footer?.attributes?.socialMedias?.data.map((item) => (
                  <a
                    key={item.attributes.name}
                    href={item.attributes?.href ?? ''}
                    className={`${sectionStyle.textColor.secondary} hover:text-gray-300`}
                  >
                    <span className="sr-only">{item.attributes.name}</span>
                    {item.attributes.image?.data ? (
                      <NextImage media={item.attributes.image.data} classImg={'object-cover'} width={20} height={20} />
                    ) : (
                      <HeroIcon icon={item.attributes.icon} outline={true} />
                    )}
                  </a>
                ))}
              </div>
              <p className={`mt-8 text-base ${sectionStyle.textColor.secondary} md:mt-0 md:order-1 text-center`}>
                &copy; {footer?.attributes?.copyright}
              </p>
            </div>
            <VismaSection locale={pageData.attributes?.locale || DEFAULT_LANGUAGE} />
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
